/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a",
    strong: "strong",
    h2: "h2",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I offer the following products and services."), "\n", React.createElement(_components.h3, null, "Technical Editing"), "\n", React.createElement(_components.p, null, "Editing exiting guides or OpenAPI specs."), "\n", React.createElement(_components.p, null, "My editing process includes:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Editing for clarity and conciseness"), "\n", React.createElement(_components.li, null, "Checking code samples"), "\n", React.createElement(_components.li, null, "Reviewing for consistent terminology"), "\n", React.createElement(_components.li, null, "And more!"), "\n"), "\n", React.createElement(_components.p, null, "I have ", React.createElement(_components.a, {
    href: "/oas-editing"
  }, "systems in place"), " and an established toolchain that make the process speed along."), "\n", React.createElement(_components.h3, null, "Technical Writing"), "\n", React.createElement(_components.p, null, "Create new guides or OpenAPI (OAS) specs.\nI will create your OAS spec from scratch and/or write usage guides.\nDuring the writing process I edit my own work and validate all code samples are accurate."), "\n", React.createElement(_components.h3, null, "Style Guide"), "\n", React.createElement(_components.p, null, "A style guide to maintain consistency in your guides and OpenAPI (OAS) specs."), "\n", React.createElement(_components.p, null, "I will review your existing documentation and create a style guide based on the standards I see.\n(Delivered in the format of your choice.)\nA ", React.createElement(_components.a, {
    href: "/style-guide"
  }, "style guide"), " is key in maintaining consistently great docs.\nThe best time to create a style guide is after you have an existing OAS spec and multiple guides.\nWithout a style guide it is common to have a set of standards that are generally followed,\nbut regularly deviated from.\n(For example, irregular title case, periods at the end of OAS descriptions, etc.)"), "\n", React.createElement(_components.h3, null, "Documentation Audit"), "\n", React.createElement(_components.p, null, "A complete audit of your existing documentation.\nIncludes recommendations for improvement (menu navigation, documentation format, etc.),\nFAQs, and a list of any mistakes found in the docs or code samples."), "\n", React.createElement(_components.h3, null, "Docs-as-Code System"), "\n", React.createElement(_components.p, null, "This is my most comprehensive offering.\nThe deliverable is a full ", React.createElement(_components.a, {
    href: "/docs-as-code"
  }, "docs-as-code"), " system set up to work with the docs tool of your choice*."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " The tool must support CI/CD."), "\n", React.createElement(_components.h2, null, "Pricing"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "Product"), React.createElement(_components.th, null, "Price"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, "Technical Editing (Including OAS Specs)"), React.createElement(_components.td, null, "$75/hr")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Technical Writing"), React.createElement(_components.td, null, "$80/hr")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Style Guide"), React.createElement(_components.td, null, "Starting at $750")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Style Guide + Basic Style Fixes"), React.createElement(_components.td, null, "Starting at $875")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Docs Audit"), React.createElement(_components.td, null, "Starting at $3500")), React.createElement(_components.tr, null, React.createElement(_components.td, null, "Full Docs-as-code System"), React.createElement(_components.td, null, "Contact me")))), "\n", React.createElement(_components.p, null, "For custom pricing options, please contact me directly."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
